import React, { useEffect, useState } from 'react'
import { motion, useScroll, useTransform, useViewportScroll } from "framer-motion"
import { Link, useLocation } from 'react-router-dom';


const DATA = [
  {
    title: 'ANASAYFA',
    link: '/'
  },
  {
    title: 'KURUMSAL',
        link: '/hakkimizda',
    dropDown: [
      {
        title: 'Hakkımızda',
        link: '/hakkimizda',
      },
      {
        title: 'Vizyon',
        link: '/hakkimizda',
      },
      {
        title: 'Misyon',
        link: '/hakkimizda',
      },


    ]

  },
  {
    title: 'ÜRETİM',
    link: '/uretim/kompozit-yat-imalati',
    dropDown: [
      {
        title: 'KOMPOZİT YAT İMALATI',
        link: '/uretim/kompozit-yat-imalati',
      },

      {
        title: 'KOMPOZİT BORU PROFİL İMALATI',
        link: '/uretim/kompozit-boru-profil-imalati',

      },
      
      {
        title: 'SAVUNMA VE HAVACILIK',
        link: '/uretim/savunma-ve-havacilik',

      },
      
      {
        title: 'KOMPOZİT OTOMOBİL PARÇA ÜRETİMİ',
        link: '/uretim/kompozit-otomobil-parca-uretimi',

      },
      {
        title: 'ÖZEL PROJE KOMPOZİT',
        link: '/uretim/ozel-proje-kompozit',

      }
    ]
  },
  {
    title: 'HİZMETLERİMİZ',
        link: '/hizmetlerimiz/redlory-kompozit-kalip-tasarimi-ve-imalati',
    dropDown: [
      {
        title: 'REDLORY KOMPOZİT KALIP TASARIMI VE İMALATI',
        link: '/hizmetlerimiz/redlory-kompozit-kalip-tasarimi-ve-imalati'
      },
      {
        title: 'REDLORY KOMPOZİT KALIP PROTOTİPLEME',
        link: '/hizmetlerimiz/redlory-kompozit-kalip-prototipleme'
      },
      {
        title: 'REDLORY KOMPOZİT DÜŞÜK ADETLİ ÜRETİM',
        link: '/hizmetlerimiz/redlory-kompozit-dusuk-adetli-uretim'
      },
      // {
      //   title: 'Şarj İstasyonları',

      // },
    ]
  },
  {
    title: 'İLETİŞİM',
        link: '/iletisim'
  },

  // {
  //   title: 'TR',

  //   dropDown: [
  //     {
  //       title: 'EN',
    
  //     },

  //   ]

  // },
]


const Header = () => {
  const [toggle, setToggle] = useState(false)
  const location = useLocation();
  const { scrollY } = useViewportScroll();

  const bgColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(0, 0, 0, 0)", "rgba(255, 255, 255, 1)"]
  );

  const fontSizez = useTransform(
    scrollY,
    [0, 2 * 16],
    ["16px", "14px"]
  );


  const textColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
  );

  const toggleMenuIconColor = useTransform(
    scrollY,
    [0, 2 * 16],
    ["rgba(255, 255, 255, 1)", "rgba(0, 154, 162, 1)"]
  );



  const iconFilterInvert = useTransform(
    scrollY,
    [0, 2 * 16],
    [1, 0]
  );


  useEffect(() => {
    setToggle(false)
  }, [location])




  console.log(location)





  return (
    <header className="flex justify-center   fixed w-full   left-0 z-[100] ">
      <motion.div className="w-full h-[5.2rem] 3xl:container  fixed  flex items-center p-0  !bg-white border-b border-b-customGray1 ">

        <div className='grid grid-cols-10 w-full'>
          <div className='px-5 lg:px-0 col-span-10  lg:col-start-2  lg:col-end-10   '>
            <div className='grid grid-cols-2 lg:grid-cols-4  '>
            <div className="flex  relative z-100 w-full items-center h-full">
              <Link to={'/'} className=' block'>
                <img className='h-[2.1rem]' src={`${!toggle?'/images/logo.svg ':'/images/logo-white.svg'}`} /></Link>

            </div>


      
            <div  onClick={() => setToggle(!toggle)} className='lg:hidden flex w-full justify-end items-center'>
                <div className='h-5 w-8  flex items-center justify-center relative'>
                    <div className={`w-8 h-0.5  ${!toggle?'bg-redloryColor':'bg-white'} absolute top-0 transition-all duration-500 ${!toggle?'top-0':'top-2.5'}  ${!toggle?'rotate-0':'rotate-45'}`} ></div>
                    <div className={`w-8 h-0.5 ${!toggle?'bg-redloryColor':'bg-white'} ${!toggle?'top-0':'top-2.5'} absolute transition-all duration-500  ${!toggle?'top-2.5':'top-2.5'} ${!toggle?'translate-x-0':'translate-x-96'}` }></div>
                    <div className={`w-8 h-0.5 ${!toggle?'bg-redloryColor':'bg-white'} ${!toggle?'top-0':'top-2.5'} absolute bottom-0 transition-all duration-500  ${!toggle?'top-5':'top-2.5'} ${!toggle?'rotate-0':'-rotate-45'}`}></div>

                </div>
            </div>



            <nav className="lg:flex items-center  hidden col-span-2 justify-end ">

              <ul className='flex  h-full'>
                {
                  DATA.map((item, index) => {
                    return (
                      <li key={index} className='group cursor-pointer relative flex  items-center  px-4 transition-all duration-500 '>
                        <Link to={item.link}><motion.h6 className={`${item.link === location.pathname ? '!text-customGray2' : 'text-redloryColor'} flex  group-hover:!text-tedakblue !text-14 openSans font-semibold hover:!text-black transition-all duration-500`}>{item.title}

                          {/* {item.dropDown && 
                    
                    (
                      
                      
                      <motion.img style={{ filter: ` invert(${iconFilterInvert})` }} className='ml-2 transform group-hover:rotate-90 transition-all duration-500 ' src='/image/header/miniHook.svg' />
                      
                      
                      )} */}
                        </motion.h6></Link>
                        {
                          item.dropDown &&
                          <ul className='absolute opacity-0 top-[3.95rem] left-0 invisible group-hover:visible  bg-white  group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max shadow-sm'>
                            {
                              item.dropDown.map((item, index) => {
                                return (
                                  <li key={index} className='group/innerDropDown '>
                                    <Link to={item.link}><motion.h6  className={` px-4 py-2 flex group-hover/innerDropDown:!text-tedakblue !text-14 openSans font-semibold hover:!bg-customGray2`}> {item.title}{item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}</motion.h6></Link>

                                    {
                                      item.dropDown &&
                                      <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                                        {
                                          item.dropDown.map((item, index) => {
                                            return (
                                              <Link to={item.link}><li key={index} className='hover:text-tedakblue'>
                                                <motion.h6 style={{ fontSize: fontSizez }} className='  pl-5 '> {item.title}</motion.h6>
                                              </li>
                                              </Link>
                                            )
                                          })
                                        }
                                      </ul>
                                    }

                                  </li>
                                )
                              })
                            }
                          </ul>
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </nav>


            <div className='hidden lg:flex items-center justify-end w-full !text-16 openSans '>
              <div className='mr-3 border-b border-customGray2 text-customGray2'>TR</div>
              <div>EN</div>
            </div>
            </div>
          </div>
        </div>










        {toggle &&
          <nav className={`absolute top-0 left-0 h-screen overflow-auto w-full bg-redloryColor   -z-10 pt-24 px-5 transform  transition-all duration-500 ${toggle ? 'opacity-100' : 'opacity-0'}`}>

            <div className='h-screen absolute top-0 left-0 overflow-hidden z-20'></div>
            <ul className='w-full z-50 relative  '>
              {
                DATA.map((item, index) => {
                  return (
                    <li key={index} className='w-full group'>

                      <div className='flex items-center h-16 '>
                        <Link className='' to={item.link} onClick={(e) => item.link ? '' : e.preventDefault()} >
                          <motion.h6 className={' w-24 text-white text-xl'}>{item.title}</motion.h6>
                        </Link>
                        <div className='w-full '>
                          {item.dropDown && (<div className='w-full block '></div>)}
                        </div>
                      </div>
                      {
                        item.dropDown &&
                        <ul className=' h-0 opacity-0 group-hover:opacity-100 group-hover:h-auto overflow-hidden transition-all duration-500 pl-[10%] '>
                          {
                            item.dropDown.map((item, index) => {
                              return (
                                <li key={index} className='group/innerDropDown py-1'>
                                  <div className='flex items-center '>
                                    <Link to={item.link} className=''>
                                      <motion.h6 className={'text-nowrap text-white text-base'}>{item.title}</motion.h6>
                                    </Link>
                                    <div className='w-full '>
                                      {item.dropDown && (<div className='w-full block ml-4'><motion.img src='/image/header/miniHook.svg' /></div>)}
                                    </div>
                                  </div>

                                  {
                                    item.dropDown &&
                                    <ul className='h-0 group-hover/innerDropDown:h-auto overflow-hidden transition-all duration-500 opacity-0 group-hover/innerDropDown:opacity-100 pl-[20%]'>
                                      {
                                        item.dropDown.map((item, index) => {
                                          return (
                                            <Link to={item.link}><li key={index} className='space-y-1'>
                                              <motion.h6 className='text-white text-sm' > {item.title}</motion.h6>
                                            </li>
                                            </Link>
                                          )
                                        })
                                      }
                                    </ul>
                                  }

                                </li>
                              )
                            })
                          }
                        </ul>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        }


      </motion.div>

    </header>
  )
}

export default Header