export const urunlerData = [
    {
        id: 0,
        url: 'kompozit-yat-imalati',
        icon: '/images/menu/5.svg',
        image_main: '/images/uretim/kompozit-yat-imalati/banner.jpg',
        title: 'KOMPOZİT YAT İMALATI',
        content_text_1: ['Kompozit yatların tercih edilmesinin başlıca sebepleri, hafif, sağlam ve bakımının ahşap teknelere göre daha kolay olmasıdır. Yüksek standartlarda kompozit yat üretimi için en gelişmiş teknolojik sistemleri yakından takip eden Redlory Kompozit, tasarımda ve teknolojide uluslararası standartları benimseyen yat tutkunlarına özgün tasarım alanında yenilikçi çözümler sunmaktadır. Talepleriniz doğrultusunda kompozit olarak yat üretimi yapılmaktadır. İstenilen türde yat için kalıp çıkartılıp dökülmesi ve iç donatımının yapılmasını sağlayacak donanım, ekibe ve bilgiye sahip olduğumuzu belirterek siz değerli müşterilerimize hizmet sunmaktayız.'],
        content_text_2: '',
        image: [
            '/images/uretim/kompozit-yat-imalati/1.jpg',
            '/images/uretim/kompozit-yat-imalati/2.jpg',
        ],


    },


    {
        id: 1,
        url: 'kompozit-boru-profil-imalati',
        icon: '/images/menu/1.svg',
        image_main: '/images/uretim/kompozit-boru-profil-imalati/banner.jpg',
        title: 'KOMPOZİT BORU PROFİL İMALATI',
        content_text_1: [
            '3 metre boya kadar, çap sınırı olmaksızın her türlü karbon fiber ve fiber glass boru profili üretimi yapıyoruz. Projenizin gereksinimlerine göre ölçüleri artırabilir, en yüksek kalite standartlarında üretim gerçekleştirebiliriz.',
            'Üretim süreçlerimizin tamamını kendi tesisimizde yönetiyor, ham madde girişinden teslimata kadar her aşamada titizlikle çalışıyoruz. Talebinize göre farklı renk ve desen seçenekleri sunarak estetik ve işlevselliği bir araya getiriyoruz.',
            'Havacılık sektöründe kullanılan üstün kaliteli kumaşlar ile ürettiğimiz boru profilleri, ihtiyaca bağlı olarak UV dayanımı yüksek ithal epoksi son kat vernik veya farklı vernik türleri ile teslim edilebilmektedir. Ayrıca, mukavemet, kimyasal dayanıklılık gibi teknik gereksinimlere uygun tasarımlar yaparak projelerinize özel çözümler sunuyoruz.',
            'Kaliteli, dayanıklı ve özel üretim boru profilleri için bizimle iletişime geçin!'
        ],
        content_text_2: '',
        image: [
            '/images/uretim/kompozit-boru-profil-imalati/1.jpg',
            '/images/uretim/kompozit-boru-profil-imalati/2.jpg',
        ],

    },



    {
        id: 2,
        url: 'savunma-ve-havacilik',
        icon: '/images/menu/3.svg',
        image_main: '/images/uretim/savunma-ve-havacilik/banner.jpg',
        title: 'SAVUNMA VE HAVACILIK',
        content_text_1: ['Redlory Kompozit olarak savunma ve havacılık sanayisi için yüksek kaliteli kompozit ürün ve parçalar üretiyoruz. İleri teknoloji ve mühendislik çözümlerimizle, dayanıklılık ve performans gerektiren projelerde güvenilir bir iş ortağı olarak öne çıkıyoruz. Yenilikçi üretim tekniklerimizle, müşterilerimize uluslararası standartlarda, hafif ve sağlam kompozit ürünler sunuyoruz. Redlory Kompozit ile güvenli ve üstün performanslı çözümler sağlıyoruz.'],
        content_text_2: '',
        image: [
            '/images/uretim/savunma-ve-havacilik/1.jpg',
            '/images/uretim/savunma-ve-havacilik/2.jpg',
        ],


    },



    {
        id: 3,
        url: 'kompozit-otomobil-parca-uretimi',
        icon: '/images/menu/2.svg',
        image_main: '/images/uretim/kompozit-otomobil-parca-uretimi/banner.jpg',
        title: 'KOMPOZİT OTOMOBİL PARÇA ÜRETİMİ',
        content_text_1: ['Redlory Kompozit olarak, otomotiv endüstrisinde büyük ve ağır üretim aletlerinin karşılaştığı zorlukları karbon fiber üretim araçlarımızla ortadan kaldırıyoruz. Karbon fiberden ürettiğimiz araç parçaları, FEA hesaplamaları kullanılarak, alüminyum veya çelikten üretilen parça ve fikstürlere kıyasla %75’e kadar ağırlık azaltma imkanı sağlar. Redlory Kompozit ile daha hafif ve dayanıklı çözümler sunarak, otomotiv sektöründe yenilikçi ve verimli üretim süreçleri sağlıyoruz.'],
        content_text_2: '',
        image: [
            '/images/uretim/kompozit-otomobil-parca-uretimi/1.jpg',
            '/images/uretim/kompozit-otomobil-parca-uretimi/2.jpg',
        ],


    },




    {
        id: 4,
        url: 'ozel-proje-kompozit',
        icon: '/images/menu/4.svg',
        image_main: '',
        title: 'ÖZEL PROJE KOMPOZİT',
        content_text_1: ['Redlory Kompozit olarak, özel parça üretiminde uzmanız. İleri teknoloji ve yenilikçi çözümlerle, müşterilerimizin ihtiyaçlarına uygun, yüksek kaliteli ve dayanıklı kompozit parçalar üretiyoruz. Özel projeleriniz için güvenilir ve üstün performanslı çözümler sunuyoruz. '],
        content_text_2: '',
        image: [

        ],


    },
]